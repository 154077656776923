import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import COMMON_COMPONENTS from './commonComponents';
import { HeadingProps } from './MarkdownComponents';
import OrangeButton from 'components/Button/OrangeButton';
import BlueButton from 'components/Button/BlueButton';
import PurpleButton from 'components/Button/PurpleButton';
import RedButton from 'components/Button/RedButton';
import GreenButton from 'components/Button/GreenButton';
import { UnorderedList } from '@virgilsecurity/virgil-ui/src/components/Markup';

import styles from './IndexMdxProvider.module.css';
import classNames from 'classnames';

const IndexHeading2: React.FC<HeadingProps> = props => {
	return <h2 {...props} className={classNames(props.className, styles.h2)} />;
};

export const IndexUnorderedList: React.FC<React.HTMLAttributes<HTMLUListElement>> = props => {
	return <UnorderedList {...props} className={classNames(styles.ul, props.className)} />;
};

const INDEX_PAGE_COMPONENTS = {
	...COMMON_COMPONENTS,
	h2: IndexHeading2,
	ul: IndexUnorderedList,
	OrangeButton,
	BlueButton,
	PurpleButton,
	RedButton,
	GreenButton,
};

const IndexMdxProvider: React.FC = ({ children }) => (
	<MDXProvider components={INDEX_PAGE_COMPONENTS}>
		<article className="markdown">{children}</article>
	</MDXProvider>
);

export default IndexMdxProvider;
