import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { IndexPageQuery } from 'src/types/IndexPageQuery';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import IndexMdxProvider from 'src/components/Markdown/IndexMdxProvider';
import DocsLayout from 'src/layout/DocsLayout';
import { globalHistory } from '@reach/router';
import siteMeta from 'content/site-meta.yml';

interface IndexPageProps {
	data: {
		mdx: IndexPageQuery;
	};
}

const IndexPage: React.FC<IndexPageProps> = props => {
	const { data } = props;
	const title = data.mdx.frontmatter.tab_title;
	const description = data.mdx.frontmatter.meta_description;
	const keywords = data.mdx.frontmatter.meta_keywords.join(', ');
	const canonicalUrl = globalHistory.location.origin || siteMeta.url;
	const image = canonicalUrl ? `${canonicalUrl.replace(/\/$/, '')}/${siteMeta.banner}` : null;

	return (
		<React.Fragment>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={keywords} />
				<link rel="canonical" href={globalHistory.location.href} />
				{image && <meta name="image" content={image} />}
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				{image && <meta property="og:image" content={image} />}
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				{image && <meta name="twitter:image" content={image} />}
			</Helmet>
			<DocsLayout>
				<IndexMdxProvider>
					<MDXRenderer>{data.mdx.body}</MDXRenderer>
				</IndexMdxProvider>
			</DocsLayout>
		</React.Fragment>
	);
};

export const pageQuery = graphql`
	query IndexPageQuery {
		mdx(fileAbsolutePath: { regex: "/content/home/" }) {
			id
			body
			frontmatter {
				tab_title
				meta_description
				meta_keywords
			}
		}
	}
`;

export default IndexPage;
