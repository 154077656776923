import React from 'react';

import styles from './Button.module.css';
import { GatsbyLinkProps } from 'gatsby';
import Button from './Button';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PurpleButton: React.FC<GatsbyLinkProps<any>> = ({ ref, ...props }) => {
	return <Button {...props} extraClass={styles.PurpleButton} />;
};

export default PurpleButton;
