import React from 'react';

import styles from './Button.module.css';
import classNames from 'classnames';
import { Link, GatsbyLinkProps } from 'gatsby';
import { ReactComponent as OuterLinkIcon } from 'icons/Icon-L-External.svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ButtonProps extends GatsbyLinkProps<any> {
	extraClass?: string;
}

const Button: React.FC<ButtonProps> = ({ extraClass, ref, ...props }) => {
	if (props.to[0] === '/') {
		return (
			<Link
				to={props.to}
				{...props}
				className={classNames(extraClass, styles.Button, props.className)}
			/>
		);
	}
	return (
		<a
			href={props.to}
			className={classNames(extraClass, styles.Button, props.className)}
			target="_blank"
			rel="noopener noreferrer"
		>
			{props.children}
			<OuterLinkIcon className={styles.outerIcon} />
		</a>
	);
};

export default Button;
