import React from 'react';

import styles from './Button.module.css';
import { GatsbyLinkProps } from 'gatsby';
import Button from './Button';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BlueButton: React.FC<GatsbyLinkProps<any>> = props => {
	return <Button {...props} extraClass={styles.BlueButton} />;
};

export default BlueButton;
